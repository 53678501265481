<template>
  <b-row>
    <b-col sm="12" xl="6">
      <c-card title="Pengaturan Printer">
        <dl class="row">
          <dt class="col-sm-3">Status QZ-Tray</dt>
          <dd class="col-sm-9">{{printerStatus}}</dd>
        </dl>
        <form @submit.prevent="submitForm">
          <b-form-group label="Printer Untuk Label" label-for="labelPrinter">
            <b-form-select id="labelPrinter" name="labelPrinter" v-model="labelPrinter" :options="printerDevices"></b-form-select>
          </b-form-group>
          <b-form-group label="Printer Untuk Nota" label-for="notaPrinter">
            <b-form-select id="notaPrinter" name="notaPrinter" v-model="notaPrinter" :options="printerDevices"></b-form-select>
          </b-form-group>
          <b-button class="mt-1" type="submit" variant="primary">Simpan</b-button>
        </form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import qzMixin from '@/utils/mixins/qz-tray'
import CCard from '@/utils/components/CCard.vue'
import {BButton,BFormSelect,BFormGroup} from 'bootstrap-vue'

export default {
  components: { BFormSelect, CCard, BButton, BFormGroup },
  mixins:[qzMixin],
  data(){
    return {
      labelPrinter:null,
      notaPrinter:null,
    }
  },
  methods:{
    submitForm(){
      localStorage.setItem('labelPrinter',this.labelPrinter)
      localStorage.setItem('notaPrinter',this.notaPrinter)
      this.notify("Pilihan Printer Berhasil Disimpan!","OK")
    }
  },
  mounted(){
    this.labelPrinter = localStorage.getItem('labelPrinter')||null
    this.notaPrinter = localStorage.getItem('notaPrinter')||null
  }
}
</script>

<style>

</style>